<template>
	<div
		class="full-height pa-10-20"
	>
		<h6>{{ program.name }}</h6>

		<Search
			class="mt-10 box"
			:search="search"
			:option="search_option"

			@reset="reset"
			@click="getSearch"
		>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.branchType"
				@change="getSearch(1)"
			>
				<option value="">영업단 선택</option>
				<template
					v-for="(agency, index) in codes.agency_type"
				>
					<option
						v-if="index > 0 && index <= codes.agency_type.length - 2 && index > user.roleCode"
						:key="'agency_' + index"
						:value="agency.code"
					>{{ agency.name }} </option>
				</template>
			</select>
		</Search>

		<div class="mt-10 pa-10 bg-white flex-column overflow-y-auto">

			<div class="justify-space-between">
				<div>총 {{ search.total_count }} 건</div>
				<div>
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
					<select
						class="pa-5-10 mr-10 size-px-12"
						v-model="search.size"
						@change="getSearch(1)"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>
			</div>

			<table
				v-if="items.length > 0"
				class="mt-10 table table-even top-line-identify"
			>
				<colgroup>
					<col width="50px" />
					<col width="80px" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="130px" />
					<col width="auto" />

					<col width="auto" />
					<col width="130px" />
					<col width="100px" />
				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>구분</th>
					<th>아이디</th>
					<th>상호</th>
					<th>영업점 코드</th>

					<th>사업자 구분</th>
					<th>사업자번호</th>
					<th>대표자명</th>
					<th>수수료율</th>
					<th>등록일</th>

					<th>상태</th>
					<th>하위 조직</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td>{{ (10 * (search.page ? search.page - 1 : '0')) + index + 1 }}</td>
					<td>{{ item.branch_name }}</td>
					<td>{{ item.id }}</td>
					<td class="text-left">{{ item.shopName }}</td>
					<td>{{  item.code }}</td>

					<td>{{  item.business_name }}</td>
					<td>{{ item.businessNumber  }}</td>
					<td>{{ item.name  }}</td>
					<td>
                        <ul>
                            <li class="flex justify-space-between">
                                <span>배달비</span>
                                <span>{{ item.quickFee  }}%</span>
                            </li>
                            <li class="flex justify-space-between">
                                <span>월세</span>
                                <span>{{ item.monthlyFee  }}%</span>
                            </li>
                            <li class="flex justify-space-between">
                                <span>즉시정산</span>
                                <span>{{ item.instantFee  }}%</span>
                            </li>
                            <li class="flex justify-space-between">
                                <span>D-Day</span>
                                <span>{{ item.nonInstantFee  }}%</span>
                            </li>
                        </ul>
                    </td>
					<td>{{  item.regDate }}</td>
					<td :class="'color-' + item.state_color">{{ item.state_name }}</td>
					<td>
						<button
							class="bg-green pa-5-10 size-px-12"
							@click="toSub(item)"
						>하위조직 보기</button>
					</td>
					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center top-line-identify mt-10"
			>
				<div class="text-center pa-20">
					<v-icon
						class="size-px-48 color-icon"
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>
		</div>

        <Pagination
            :program="program"
            :align="'center'"
            :options="search"

            class="mt-auto"
            @click="getSearch"
        ></Pagination>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"

			@finish="is_excel = !is_excel"
		></Excel>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			width="1200px"
			height="550px"

			@close="close"
		>
			<AgencySubList
				slot="modal-content"
				:item="item_sub"
				:user="user"

			></AgencySubList>
		</Modal>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";
import AgencySubList from "@/view/Agency/AgencySubList";
export default {
	name: 'AgencyList',
	components: {AgencySubList, Modal, Excel, Search, Pagination}
	,data: function(){
		return {
            user: [],
			program: {
				name: '하위 영업점 관리'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.$route.query.page ? this.$route.query.page : 1
				,size: this.$route.query.size ? this.$route.query.size : 10
				,search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
				,keyword: this.$route.query.keyword ? this.$route.query.keyword : ''
				,branchType: this.$route.query.branchType ? this.$route.query.branchType : ''
				,total_count: 0
			}
			,search_option: {
				search_type: [
					{ name: '아이디', column: 1}
					, { name: '영업점명', column: 2}
					, { name: '사업자번호', column: 3}
					, { name: '대표자명', column: 4}
				]
			}
			,add_agency_type: this.codes.agency_type
			,items: []
			,is_excel: false
			,excel_data: {
				name: '영업점 목록'
				,header: [
					{ key: 0, name: '구분', column: 'branchTypeName'}
					,{ key: 0, name: '아이디', column: 'id'}
					,{ key: 0, name: '총판명', column: 'branchName'}
					,{ key: 0, name: '지사명', column: 'distributorName'}
					,{ key: 0, name: '대리점명', column: 'agencyName'}
					,{ key: 0, name: '리셀러명', column: 'resellerName'}
					,{ key: 0, name: '상호', column: 'shopName'}
					,{ key: 0, name: '영업점 코드', column: 'code'}
					,{ key: 0, name: '사업자 구분', column: 'businessTypeName'}
					,{ key: 0, name: '사업자번호', column: 'businessNumber'}
					,{ key: 0, name: '대표자명', column: 'name'}
					,{ key: 0, name: '배달비 수수료율', column: 'quickFee'}
					,{ key: 0, name: '월세 수수료율', column: 'monthlyFee'}
					,{ key: 0, name: '즉시 정산 수수료율', column: 'instantFee'}
					,{ key: 0, name: '일반 정산 수수료율', column: 'nonInstantFee'}
					,{ key: 0, name: '등록일', column: 'regDate'}
					,{ key: 0, name: '상태', column: 'stateName'}
					,{ key: 0, name: '휴대폰', column: 'hp'}
					,{ key: 0, name: '정산 은행', column: 'bankName'}
					,{ key: 0, name: '계좌번호', column: 'account'}
					,{ key: 0, name: '예금주', column: 'accountHolder'}
					,{ key: 0, name: '이메일', column: 'email'}
					,{ key: 0, name: '보증보험 증권', column: 'performanceBondAmount'}
				]
				,content: null
			}
			,item_sub: {

			}
			,is_modal: false
			,modal_option: {
				title: '하위 조직'
				,top: true
				,bottom: false
			}
			,total_cnt: 0
		}
	}

	,computed: {
		item_list: function(){
			let self = this
			return this.items.filter(function(item){

				for(let i = 0; i < self.codes.agency_type.length; i++){
					if(item.branchType == self.codes.agency_type[i].code){
						item.branch_name = self.codes.agency_type[i].name
						break
					}
				}

				for(let i = 0; i < self.codes.P02.length; i++){
					if(item.businessType == self.codes.P02[i].TOTAL_CODE){
						item.business_name = self.codes.P02[i].code_name
						break
					}
				}

				for(let i = 0; i < self.codes.status_agency.length; i++){
					if(item.state == self.codes.status_agency[i].code){
						item.state_name = self.codes.status_agency[i].name
						item.state_color = self.codes.status_agency[i].color
						break
					}
				}

				return item
			})
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$layout.onLoading()
                if(this.search.search_type) {
                    this.search.keywordType = this.search.search_type
                } else {
                    delete this.search.keywordType
                }
				const result = await this.$Axios({
					method: 'get'
					,url: 'branch/'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.data
					this.search.total_count = result.data.totalCount
					localStorage.setItem('query', JSON.stringify(this.search))
                    console.log(this.items)
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,toDetail: function(item){
			console.log(item)
			this.$layout.push( { name: 'AgencyDetail', params: { idx: item.idx }, query: '', not_query: true})
		}
		,toSub: function(item){
			this.item_sub = item
			this.is_modal = true
		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			//this.$layout.push( { name: this.$route.name, params: this.$route.params, query: this.search})

			this.getData()
		}

		,toExcel: async function(){

			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'branch/excel'
				})

				if(result.success){
                    console.log(result.data, 'excel')
					this.excel_data.content = result.data
					this.is_excel = true
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,close: function(){
			this.is_modal = false
		}
		,reset: function(){
			this.search = {
				page: 1
				,size: 10
				,search_type: ''
				,keyword: ''
				,branchType: ''
				,total_count: 0
			}
			this.getData()
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
		this.getData()
	}
}
</script>
<style scoped>
td {
    word-break: break-all;
}
</style>